import React from 'react';
import ManufacturerBanner from '../../Components/ManufacturerBanner';
import ManufacturerSec1 from '../../Components/ManufacturerSec1';
import ManufacturerSec2 from '../../Components/ManufacturerSec2';
import ManufacturerSec3 from '../../Components/ManufacturerSec3';
import ManufacturerSec4 from '../../Components/ManufacturerSec4';
import ManufacturerSec5 from '../../Components/ManufacturerSec5';

const Manufacturer = () => {
    return (
        <div>
            <ManufacturerBanner/>
            <ManufacturerSec1/>
            <ManufacturerSec2/>
            <ManufacturerSec3/>
            <ManufacturerSec4/>
            <ManufacturerSec5/>
        </div>
    );
};

export default Manufacturer;