import React from "react";
import banner from '../Images/contact-banner.jpg'

const ContactUs = () => {
  return (
    <div>
      <div className="banner">
        <img alt="" src={banner}/>
        {/* Optional overlay */}
        <div className="overlay"></div>
      </div>
      {/* <p className="text-center bg-[#fcfcfc] p-10">Contact Us</p> */}
    </div>
  );
};

export default ContactUs;
