import { createBrowserRouter } from "react-router-dom";
import Main from "../Layout/Main";
import Home from "../Pages/Home/Home";
import PrivateLabel from "../Pages/PrivateLabel/PrivateLabel";
import Manufacturer from "../Pages/Manufacturer/Manufacturer";
import ContactUs from "../Pages/ContactUs";
import AboutUs from "../Pages/AboutUs";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Main></Main>,
    children: [
      {
        path: "/",
        element: <Home></Home>,
      },
      {
        path: "/private-label",
        element: <PrivateLabel></PrivateLabel>,
      },
      {
        path: "/perfume-manufacturers",
        element: <Manufacturer></Manufacturer>,
      },
      {
        path: "/contact-us",
        element: <ContactUs></ContactUs>,
      },
      {
        path: "/who-we-are",
        element: <AboutUs></AboutUs>,
      }
    ],
  }
]);

export default router;
