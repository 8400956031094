import React from 'react';
import logo from '../../Images/nextcare-1.png';

const Footer = () => {
  return (
    <footer className="bg-black py-10 md:py-16">
      <div className="container mx-auto flex flex-col md:flex-row justify-between items-center px-4 md:px-8">
        <div className="flex flex-col items-center md:items-start mb-8 md:mb-0">
          <img src={logo} alt="Auria Perfumes" className="w-[160px] md:w-[200px] mb-4" />
          {/* <p className="text-white text-center md:text-left">Creating perfumes with soul</p> */}
        </div>

        <div className="flex flex-col md:flex-row space-y-8 md:space-y-0 md:space-x-8 text-center md:text-left">
          <div>
            <h3 className="text-lg font-semibold mb-4 text-white">Private Label</h3>
            <ul className="space-y-2">
              <li><a href="/" className="text-white hover:underline">Fine Fragrances</a></li>
              <li><a href="/" className="text-white hover:underline">Home Fragrances</a></li>
              {/* <li><a href="/" className="text-white hover:underline">Nail Polishes</a></li> */}
            </ul>
          </div>

          <div>
            <h3 className="text-lg font-semibold mb-4 text-white">Manufacturer</h3>
            <ul className="space-y-2">
              <li><a href="/" className="text-white hover:underline">Full Service Manufacturing</a></li>
              <li><a href="/" className="text-white hover:underline">Contract Manufacturing</a></li>
              <li><a href="/" className="text-white hover:underline">Launch your Perfume brand</a></li>
            </ul>
          </div>

          <div>
            <h3 className="text-lg font-semibold mb-4 text-white">Who We Are</h3>
            <ul className="space-y-2">
              <li><a href="/" className="text-white hover:underline">Who we are</a></li>
              <li><a href="/" className="text-white hover:underline">Terminology</a></li>
              <li><a href="/" className="text-white hover:underline">Our team</a></li>
            </ul>
          </div>
        </div>

        <div className="flex flex-col items-center md:items-start text-center md:text-left">
          <p className="text-white">Biravo, Narayanganj, Dhaka.</p>
          <p className="text-white">contact@nextcarefragrances.com</p>
          <p className="text-white">+88 01404-403396</p>
        </div>
      </div>

      <div className="mt-8 border-t border-[#ffffff] pt-4 text-center">
        <p className="text-gray-500">© Nextcare Fragrances. All rights reserved.</p>
      </div>
    </footer>
  );
};

export default Footer;
