import React from "react";
import "./HomeSec1.css";

const HomeSec1 = () => {
  return (
    <div className="flex flex-col justify-center items-center mt-10 w-[90vw] md:w-[80vw] mx-auto">
      <p className="text-2xl my-6 md:my-10 text-accent">NEXTCARE PERFUMES & COSMETICS</p>
      <p className="text-lg md:text-2xl md:text-5xl md:text-center mb-2 md:mb-6 md:w-[80%]">
        As a perfume and cosmetics manufacturer, Nextcare offers full
        services for third parties & contract manufacturing.
      </p>
      <p className="text-lg md:text-2xl mb-10 text-left md:text-center md:w-[80%] text-black text-opacity-70">
        In life, it's not just about what you do, but how you do it, and, above
        all, who you do it with. That's what really sets us apart: We are
        full-service perfume and cosmetics manufacturers, taking care of every
        detail, with a great team of professionals who direct and design the
        entire process, so that you feel supported and have the assurance that
        you have chosen the best solution.
      </p>
    </div>
  );
};

export default HomeSec1;
