import React from "react";

const HomeSec4 = () => {
  return (
    <div className="flex flex-col md:flex-row justify-center items-center mt-10 w-[90vw] md:w-[100vw] mx-auto">
      <div className="w-[100%] p-2 md:w-[50%] md:p-16">
        <p className="text-2xl md:text-5xl font-bold md:w-[60%] mx-auto mb-10">
          Are you a brand, retailer or celebrity?
        </p>
        <div className="w-[90%] md:hidden mb-10">
          <img
            alt=""
            src="https://auriaperfumes.com/wp-content/uploads/2023/12/grafica_w2-e1705615312232.png"
          />
        </div>
        <p className="md:w-[60%] mx-auto text-xl md:text-2xl ">
          We are specialists in B2B projects. We have more than 24 years of
          experience in the perfume industry as a manufacturer of perfumes and
          cosmetics for third parties.
        </p>
      </div>
      <div className="w-[90%] md:w-[50%] mb-10 hidden md:block">
        <img
          alt=""
          src="https://auriaperfumes.com/wp-content/uploads/2023/12/grafica_w2-e1705615312232.png"
        />
      </div>
    </div>
  );
};

export default HomeSec4;
