import React from "react";

const Section3 = () => {
  return (
    <div className="flex flex-col md:flex-row justify-center items-center gap-6 md:gap-10 w-[90vw] md:w-[80vw] my-20 mx-auto">
      <img
        className="w-full md:w-1/2 mt-28"
        src="https://www.tyoemcosmetic.com/wp-content/uploads/Your-Guide-to-Low-MOQ-Private-Label-Skin-Care-1024x768.png"
        alt=""
      />
      <p className="text-black text-3xl md:text-5xl text-left md:text-left">
        We create private label perfumes, cosmetics, hygiene, and personal care
        lines, which include facial, body, hair, and sun care products.
      </p>
    </div>
  );
};

export default Section3;
