import React from "react";
import perfume from "../../Images/perfume.mp4";

const HomeSec2 = () => {
  return (
    <div className="flex flex-col-reverse md:flex-row justify-center items-center mt-10 w-[100vw] mx-auto">
      <div className="w-[90%] md:w-[50vw] bg-gradient-to-r from-[#00677F] to-[#0D2F3A]  p-6 md:p-24">
        <p className="text-[#ffffff] text-xl md:text-4xl">
        At Nextcare, we are committed to create opportunities and fostering inclusivity by employing talented individuals with diverse abilities. Our mission goes beyond business—it's about building a genuine, ethical and meaningful project that makes a positive impact on lives and communities.
        </p>
      </div>
      <div className="w-[90%] md:w-[50vw]">
        <video className="w-full h-full object-cover" autoPlay muted loop>
          <source src={perfume} type="video/mp4" />
        </video>
      </div>
    </div>
  );
};

export default HomeSec2;