import React from "react";
import banner from "../../Images/home.mp4";

const HomeBanner = () => {
  return (
    <div className="banner">
    <video autoPlay muted loop>
      <source src={banner} type="video/mp4" />
    </video>
    {/* Optional overlay */}
    <div className="overlay"></div>
  </div>
  );
};

export default HomeBanner;
