import React from "react";

const ManufacturerSec2 = () => {
  return (
    <div className="flex flex-col justify-center mt-10 w-[100vw] md:w-[100vw] mx-auto bg-[#F5F5F5]  p-6 md:p-32">
      <p>From the design to the creation of a customized perfume:</p>
      <p className="text-2xl md:text-4xl md:w-[400px] mt-6">
        How does the full-service system work?
      </p>
      <p className="text-xl text-black text-opacity-70 md:w-[85%] mt-6">
        At Nextcare Perfumes, we understand better than anyone what it means to
        take care of all the phases of creating and producing a perfume; we take
        care of all the creative and design processes. Without neglecting any of
        them:
      </p>
      <p className="text-xl text-black text-opacity-70 md:w-[85%] mt-6">
        – Creative briefing to define your brand values: our team of
        professionals will gather your idea, give it shape, and turn it into an
        olfactory experience. <br />
        <br />
        – Branding and fragrance design, where we gather the client’s concerns
        and match them with the most relevant market trends. <br />
        <br />
        – Elaboration and proposals of the olfactory paths: Our purpose is to
        capture what inspires you, the essence of your brand, and we set in
        motion all the creative processes to achieve it, starting with the
        olfactory palette, the memories, and the emotions. Everything motivates
        you to create the olfactory path to make it a reality. <br />
        <br />
        – Manufacturing of the defined fragrance: we have an industrial plant of
        10,200 m2, equipped with clean areas, laboratories, <br />
        <br />
        We have six automatic and semi-automatic perfume production lines, which
        can handle thousands of units per hour.
        <br />
        <br />
        In addition, we pay special attention to packaging and conditioning
        since we have a qualified staff to carry out manipulations at the
        request of our customers, with a team of 200 people for Pack Off
        services (handling).
        <br />
        <br />
        – The entire packaging system we carry out in rooms equipped for this
        purpose: maceration rooms, alcoholic product packaging, and handling
        areas.
        <br />
        <br />
        – Perfume Launch: with the whole process of safety and regulatory
        testing to deliver a perfume ready to be marketed.
        <br />
        <br />
      </p>
    </div>
  );
};

export default ManufacturerSec2;
