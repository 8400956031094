import React from "react";

const HomeSec3 = () => {
  return (
    <div className='flex flex-col justify-center items-center mt-10 w-[90vw] md:w-[100vw] mx-auto'>
      <p className="md:text-center text-2xl md:text-5xl my-10 md:w-[50%] font-bold">
        PRIVATE LABEL PROJECTS. FULL SERVICE PERFUME AND COSMETICS FACTORY
      </p>
      <p className="text-center my-10 text-xl md:text-3xl">Our categories: </p>
      <div className="flex flex-col md:flex-row md:w-[99vw] mx-auto">
        <div className="md:w-[33%]">
          <img
            className="w-[90vw] md:w-[100%]"
            src="https://www.familyhandyman.com/wp-content/uploads/2021/01/multiple-air-fresheners-GettyImages-1138715632.jpg?fit=1024,1024"
            alt=""
          />
          <p className="text-center my-10 text-xl md:text-2xl font-bold w-[90vw] md:w-[100%]">Air fresheners for the home</p>
        </div>
        <div className="md:w-[33%]">
          <img
            className="w-[90vw] md:w-[100%]"
            src="https://bizweb.dktcdn.net/100/335/381/articles/z2403261370319-498b664e0164b834c70fc363758729db.jpg?v=1616904134790"
            alt=""
          />
          <p className="text-center my-10 text-xl md:text-2xl font-bold w-[90vw] md:w-[100%]">Perfumes</p>
        </div>
        <div className="md:w-[33%]">
          <img
            className="w-[90vw] md:w-[100%]"
            src="https://images-platform.99static.com//Nj2U_nqMwJ9nivJvOqiWgSLCSpY=/258x0:1789x1531/fit-in/500x500/99designs-contests-attachments/57/57134/attachment_57134008"
            alt=""
          />
          <p className="text-center my-10 text-xl md:text-2xl font-bold w-[90vw] md:w-[100%]">Cosmetics</p>
        </div>
      </div>
    </div>
  );
};

export default HomeSec3;
