import React from 'react';
import './Home.css'
import HomeBanner from '../../Components/HomeBanner/HomeBanner';
import HomeSec1 from '../../Components/HomeSec1/HomeSec1';
import HomeSec2 from '../../Components/HomeSec2/HomeSec2';
import HomeSec3 from '../../Components/HomeSec3/HomeSec3';
import HomeSec4 from '../../Components/HomeSec4/HomeSec4';

const Home = () => {
    return (
        <div className='home'>
            <HomeBanner/>
            <HomeSec1/>
            <HomeSec2/>
            <HomeSec3/>
            <HomeSec4/>
        </div>
    );
};

export default Home;