import React from "react";

const Section2 = () => {
  return (
    <div className="flex flex-col-reverse md:flex-row justify-center items-center gap-6 md:gap-10 w-[90vw] md:w-[80vw] mb-20 mx-auto">
      <p className="text-black text-3xl md:text-5xl text-left md:text-left">
        We produce private label perfumes for women, men, children, and also
        universal perfumes.
      </p>

      <img
        className="w-full md:w-1/2 mt-28"
        src="https://hips.hearstapps.com/hmg-prod/images/elm-070224-7703-women-perfumes-66aa8a1316fc4.jpg?crop=0.668xw:1.00xh;0.146xw,0&resize=640:*"
        alt=""
      />
    </div>
  );
};

export default Section2;
